@tailwind base;
@tailwind components;
@tailwind utilities;

.uppy-Dashboard-browse {
  @apply !text-green-400 !border-b !border-green-400 text-sm;
}

.uppy-Dashboard-browse:hover {
  @apply !border-green-600;
}

.uppy-StatusBar-spinner {
  @apply !fill-green-400;
}
.uppy-StatusBar-progress {
  @apply !bg-green-400;
}

.uppy-Dashboard-AddFiles-list {
  display: none !important;
}

.uppy-Dashboard-isFixed {
  height: auto !important;
  overflow: auto !important;
}

.gm-style .place-card-medium {
  display: none !important;
}
.gmnoprint {
  display: none !important;
}

.gm-style .gm-zoom-control {
  display: none !important;
}

@font-face {
  font-family: 'Simah';
  src: url(./Simah-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Simah';
  src: url(./Simah-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Simah';
  src: url(./Simah-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

/** {*/
/*  font-family: 'Inter var', sans-serif !important;*/
/*}*/

* {
  font-family: 'Inter var', 'Simah' !important;
}

.phone-input .MuiOutlinedInput-input {
  padding: 12px 14px !important;
  border: none;
}

.signup-form .MuiOutlinedInput-input {
  padding: 12px 16px !important;
}

.item-icon {
  height: 12px !important;
  width: 12px !important  ;
}

.border-red {
  border: 1px solid #f44336 !important;
}

.border-primary {
  border: 1px solid #0bd19f !important;
}

.sticky-item {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding: 10px;
}

.custom-icon-btn {
  border-radius: 3px !important;

  padding: 6px !important;
}

.marker-bg {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 0 50px #13285f;
  background-color: #13285f;
  opacity: 0.1;
}

.marker-bg-drag {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  transform-origin: center;
  opacity: 0.1;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  display: none !important;
}

img[alt="Google"], a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

.marker-bg-dot {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 40px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.dot-active {
  height: 20px;
  width: 20px;
  background-color: #0bd19f;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot-sm {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #707070;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-muted {
  font-size: 14px;
  color: #707070 !important;
}

/* .mt-4 {
  margin-top: 40px !important;
} */

/* .my-3 {
  margin: 10px 0px !important;
} */

.border-bottom-muted {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.text-muted-order {
  color: #969696 !important;
}

.fadeIn {
  animation: fadeIn 300ms ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fill {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.new-order-bg {
  background: #40c4ff;
}
.order-accepted-bg {
  background: #f8c629;
}
.order-in-delivery-bg {
  background: #e07921;
}

.order-completed-bg {
  background: #0bd19f;
}

.order-rejected-bg {
  background: #e73e3f;
}

.new-order-bg {
  background: #40c4ff;
}

.new-order-text {
  color: #40c4ff;
}
.order-accepted-text {
  color: #f8c629;
}
.order-in-delivery-text {
  color: #e07921;
}

.order-completed-text {
  color: #0bd19f;
}

.order-rejected-text {
  color: #e73e3f;
}

.new-order-text {
  color: #40c4ff;
}

.new-order {
  color: #cb6de2 !important;
  border: #f8e1fe !important;
  background: #f8e1fe !important;
}

.complete-order {
  background: #0bd19f33 !important;
  border: #0bd19f33 !important;
  color: #00d654 !important;
}

.green-custom {
  color: #0bd19f !important;
}

.bg-muted {
  background: #ebebeb !important;
}

.bg-green {
  background: #0bd19f !important;
}

.accepted-order {
  background-color: #fff1bb !important;
  border: #fff1bb !important;
  color: #f8a529 !important;
}

.rejected-order {
  border: #f4b9b8 !important;
  background-color: #f4b9b8 !important;
  color: #e94645 !important;
}

.my-2 {
  margin: 5px 0px !important;
}

/* .my-3 {
  margin: 10px 0px !important;
} */

.text-danger {
  color: #e94645 !important;
}

.new-order {
  border: #c3ecfd !important;
  background-color: #c3ecfd !important;
  color: #49c4fa !important;
}

.cancelled-order {
  border: #a0a0a076 !important;
  background-color: #a0a0a076 !important ;
  color: #888888 !important;
}

.delivery-in-progress-order {
  border: #e0792165 !important;
  background-color: #e0792165 !important ;
  color: #e07921 !important;
}

.finding-courier {
  border: #be65ff6a !important;
  background-color: #be65ff6a !important;
  color: #be65ff !important;
}
.p-3 {
  padding: 22px !important;
}

.p-0 {
  padding: 0 !important;
}

.chip-custom {
  border-radius: 5px !important;
  height: 37px !important;
}

.bg-dark {
  background-color: #4b4b4b !important;
}

.max-width-chip {
  max-width: 180px !important;
}

.list-column-title {
  color: #888888 !important;
}

.border-green {
  border: 1px solid #0bd19f;
  border-radius: 3px;
}

.p-2 {
  padding: 10px;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.input-label {
  font-weight: 600 !important;
  color: black !important;
}

.font-20 {
  font-size: 20px !important;
}

.bg-gray-300 {
  background-color: #f0f2f2 !important;
}

.order-info {
  display: flex;
  align-items: flex-start;
}

.title {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: 10px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

.ml-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}

.text-decoration-none {
  text-decoration: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pac-container:after {
  content: none !important;
}

.text-muted-100 {
  color: #bdbdbd !important;
}
.align-self-start {
  align-self: flex-start !important;
}

/* .py-2 {
  padding: 10px 0px !important;
} */

.text-capitalize {
  text-transform: uppercase !important;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff !important;
}

.login-bg {
  padding: 80px;
  background-color: #0bd19f;
}

.h-100 {
  height: 100% !important;
  min-height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-80 {
  width: 90% !important;
  max-width: 140px !important;
}

.shake-top {
  animation: shake-top 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}
.spacing {
  letter-spacing: 1.5px;
}

@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

.text-dark {
  color: black !important;
}

.text-muted {
  color: #545454;
}

/* .mt-3 {
  margin-top: 20px;
} */

.green {
  color: rgb(29, 198, 152) !important;
  font-weight: 900;
}

.menuItem-img {
  height: 64px;
  width: 64px;
  border-radius: 2px;
  object-fit: cover;
}

.small {
  font-size: 80%;
}

/* css to remove google map credits */

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.small {
  font-size: 80%;
}

.text-gray {
  color: #6c757d;
}

.text-muted {
  color: #6c757d !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.pac-container:after {
  content: none !important;
}
/* .sticky {
  position: sticky !important;
  top: 64px !important;
  left: 0px;
  right: 0px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0px 1px 0 rgb(31 38 135 / 37%);
  backdrop-filter: blur(16.5px);
} */

.box-shadow {
  box-shadow: 0px 3px 25px #8795ab29 !important;
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.fw-normal {
  font-weight: 800 !important;
}

.color-black {
  color: black !important;
}

.text-danger {
  color: rgb(219, 23, 23);
}

/* .mt-3 {
  margin-top: 20px !important;
} */

.bg-white {
  background-color: #fff;
}

.mb-3 {
  margin-bottom: 20px !important;
}

.MuiAutocomplete-hasPopupIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 0 !important;
}

iframe#webWidget {
  z-index: 20 !important;
}

.MuiTabs-fixed.MuiTabs-scroller {
  overflow-x: auto !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: text;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
